:root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

body{
  margin: 0;
  padding: 0;
}

.contact-info{
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  color: #333333;
}

h1, h2, h3{
  color: #423338;
  font-family: 'Roboto Slab', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.title-lines {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: auto;
  text-align: center;
}

.title-lines h1, 
.title-lines h2, 
.title-lines h3 {
  position: relative;
  display: inline-block;
  padding: 0 30px;
}


.title-lines h1:before, 
.title-lines h2:before, 
.title-lines h3:before {
  left: 100%;
}

.title-lines h1:before, .title-lines h1:after, 
.title-lines h2:before, .title-lines h2:after, 
.title-lines h3:before, .title-lines h3:after {
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 1000px;
  height: 4px;
  border-top: 1px solid #c8c0bb;
  border-bottom: 1px solid #c8c0bb;
  content: '';
}

.title-lines h1:after, 
.title-lines h2:after, 
.title-lines h3:after {
  right: 100%;
}

/* header css start */
.header{
  background-image: linear-gradient(to bottom right, #D2B995, #D2B995);
}

/* header css end */

/* navbar css start */
.appNavbar {
  z-index: 100;
  -webkit-box-shadow: 0px 5px 12px 0px rgba(0,0,0,0.44);
  -moz-box-shadow: 0px 5px 12px 0px rgba(0,0,0,0.44);
  box-shadow: 0px 5px 12px 0px rgba(0,0,0,0.44);
}

.appNavBrand{
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.appNavbar .navLink {
  font-family: 'Merriweather', sans-serif;
  font-weight: bold;
  font-size: 18px;
  display: inline-block;
  padding: 8px 24px 8px 0px;
  text-decoration: none;
  color: #7C7C7D;
  border-bottom: 2px solid transparent;
}

.appNavbar .navLink:hover{
  color: #333333;
}

.appNavbar .navLink:after {
  color: #333333;
  display: block;
  content: "";
  border-bottom: 2px solid;
  transform: scaleX(0);
  transition: transform .25s ease-in-out;
}

.appNavbar .navLink:hover:after {
  color: #333333;
  transform: scaleX(1);
}

.cmsNavbar {
  background-image: linear-gradient(to bottom right, #00C4FF, #0078FF);
}

.cmsNavItem a{  
  font-family: 'Merriweather', sans-serif;
  font-weight: bold;
  font-size: 18px;
  padding: 5px 0px;
  margin-right: 15px;
  display: inline-block;
  color: #00547F;
  text-decoration: none !important;
}

.cmsNavItem a:hover{
  color: #000000B3;
}

.cmsNavBrand{
  font-family: 'Merriweather', sans-serif;
  font-weight: bold;
  margin: 0;
  padding: 8px 12px 8px 2px;
}

a.dropdown-item{
  padding-left: 15px;
  background: #fff;
}

a.dropdown-item:active,
a.dropdown-item:focus {
  outline: none;
  box-shadow: none;
}

a.dropdown-item:hover{
  background: #E9ECEF;
}

/* navbar css end */ 

/* gradient button start */
.gradient-button {
  margin: 10px;
  font-size: 16px;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #FFFFFF;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  width: 180px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  cursor: pointer;
  display: inline-block;
  border-radius: 25px;
}
.gradient-button:hover{
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.gradient-button-1 {background-image: linear-gradient(to right, #DD5E89 0%, #F7BB97 51%, #DD5E89 100%)}
.gradient-button-1:hover { background-position: right center; }

.gradient-button-2 {background-image: linear-gradient(to right, #2BC0E4 0%, #EAECC6 51%, #2BC0E4 100%)}
.gradient-button-2:hover { background-position: right center; }

.gradient-button-3 {background-image: linear-gradient(to right, #7474BF 0%, #348AC7 51%, #7474BF 100%)}
.gradient-button-3:hover { background-position: right center; }

.gradient-button-4 {background-image: linear-gradient(to right, #00d2ff 0%, #3a7bd5 51%, #00d2ff 100%)}
.gradient-button-4:hover { background-position: right center; }

.gradient-button-5 {
  background-image: linear-gradient(to right, #fff192 0%, #ffd400 51%, #fff192 100%);
  font-family: 'Merriweather', sans-serif;
  font-weight: bold;
  border: none;
  color: #333333;
}
.gradient-button-5:hover { background-position: right center; }

.gradient-button-6 {
  background-image: linear-gradient(to right, #D9B593 0%, #DB7C68 51%, #D9B593 100%);
  color:#333333; 
  font-size: 14px; 
  font-family: 'Merriweather', serif; 
  font-weight: bold;
}
.gradient-button-6:hover { background-position: right center;}


.gradient-button-5:focus{
  outline: none;
}

/* gradient button end */

/* headline css start */
.headline{
  position: relative;
  height: 650px;
}

.headline-background,
.headline-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.headline-background{
  background-image: url('./assets/images/headline-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.headline-overlay{
  background-image: linear-gradient(to bottom right, rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.65));
  z-index: 2;
}

.headline-content {
  position: relative;
  height: 100%;
  z-index: 3;
}

/* headline css end */

/* iqamah timings css start */

.prayerTable {
  color: #672308;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; 
  width: 200px;
  height: 200px;
}

.prayerTable td {
  border-bottom: 1px solid #672308;
  padding: 8px;
}

.marginIqamahTimingHeader{
  text-align: center;
  color: #672308;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; 
  margin-top: 62px; 
  font-size: 22px;
}

.prayerTimings{
  background-image: url('./assets/images/prayer-timings-border.png'), url('./assets/images/prayer-timings-bg.png'), linear-gradient(#FFFFFF, #ECE9E6);
  background-repeat: no-repeat, no-repeat;
  background-size: 100% auto;
  background-position: top, bottom;
  border: 0px;
  max-width: 330px;
  max-height: 520px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 0px;
  text-align: center;
}

/* iqamah timings css end */


/* announcement section css start */

.announcementSection{
  background-image: url('./assets/images/bg-pattern.png');
  background-repeat: repeat;
}

.announceCard{
  height: 100%;
  margin-bottom: 20px;
  padding: 1rem;
  box-shadow:0 .5rem 1rem rgba(0,0,0,.15);
  border-radius: 20px 0px 20px 0px;
}

/* announcement section css end */

/* css for Bahnschrift font */
@font-face {
  font-family: "Bahnschrift";
  src: url("./assets/fonts/BAHNSCHRIFT.TTF");
}

@font-face{
  font-family: "Scheherazade";
  src: url("./assets/fonts/Scheherazade.ttf");
}


/* footer css start */
.footer{
  background-image: linear-gradient(to bottom right, #E8C99B, #E8BC85);
}

#coverFooterPartOne {
  background-color: #313743; 
  padding: 22px;
}

.subscribeInput{
  margin-bottom: 10px;
  border: 0px;
  border-radius: 0px;
}

.subscribeInput:focus{
  box-shadow: none;
}

.subscribeButton{
  width: 100% !important;
  margin-top: 5px;
  margin-bottom: 20px;
  font-family: 'Raleway', serif;
  background-color: #AF621C;
  border: none;
  border-radius: 0px;
  margin-left: 0px !important;
  transition: .2s all ease-in-out;
}

.subscribeButton:hover,
.subscribeButton:active,
.subscribeButton:focus,
.subscribeButton:visited {
  background-color: #6F3E2C !important;
  box-shadow: none !important;
}

/* footer css end */

.img-fluid{
  max-width:100%;height:auto
}

.masjid-logo{
  border: 3px solid white; 
  border-radius: 100%; 
  max-width: 180px;
  margin: 25px;
}

/* remove underline effect from hover */
.disableAHoverEffect1:hover{
  text-decoration: none;
  color: white;
}

.disableAHoverEffect2:hover{
  text-decoration: none;
  color: black;
}

/* about us css start */

.aboutUs{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; 
  color: #313743;
}

.arabicFont{
  font-family: 'Scheherazade', serif; 
  text-align: right;
  font-size: 38px;
}

.al-bismillah{
  font-size: 46px;
}

.arabicTranslation{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: bold; 
  font-style: italic;
}

/* about us css end */

/* contact css start */

.site-blocks-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top; 
}

.site-blocks-cover.overlay {
  position: relative; 
}

.site-blocks-cover.overlay:before {   
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.05); 
}

.site-blocks-cover, .site-blocks-cover .row {
  min-height: 525px;
  height: calc(30vh); 
}

.contactCard{
  height: 100%;
  margin-top: -100px;
  margin-bottom: 200px;
  padding: 1.5rem;
  box-shadow:0 .5rem 1rem rgba(0,0,0,.15);
  border-radius: 20px 0px 20px 0px;
}

.contactInput{
  border-left: 0px; 
  border-right: 0px; 
  border-top: 0px; 
  border-radius: 0px;
}

.contactInput:focus{
  box-shadow: inset 0 -1px 0 #ddd;
}

.sendButton{
  border: 0px; 
  font-family: 'Merriweather', serif; 
  width: 120px; 
  font-weight: bold; 
  font-size: 14px; 
  color:white; 
  outline: none;
}
/* contact css end */


/* audio card css start */

.audioCard{
  margin-left: auto;
  margin-right: auto;
  max-width: 350px;
  font-family: 'Raleway', sans-serif;
  margin-top: 50px;
  border-radius: 0;
}

.audioCardImage{
  width: 100%;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -25px;
  border-radius: 0;
  box-shadow:0 .5rem .75rem rgba(0,0,0,.45);
}

.audioCardTitle{
  padding: 20px 20px 0px 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.audioCardSum {
  text-align: justify;
  padding: 0px 20px 0px 20px;
  margin-bottom: 0px;
  height: 100px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.audioCardBtnContainer {
  text-align: right;
  margin: 20px;
}

.audioCardLink{
  border: 0;
  padding: 10px 15px;
  text-decoration: none;
  color: #fff;
  background-color: #AF621C;
  border-radius: 0;
  transition: .2s all ease-in-out;
}

.audioCardLink:hover,
.audioCardLink:active,
.audioCardLink:focus {
  color: #fff;
  text-decoration: none;
  background-color: #6F3E2C !important;
  box-shadow: none !important;
}

.audioInfo{
  color: #7B7B7B;
  padding: 0px 20px 0px 20px;
  padding-bottom: .5rem;
}

.filterResetBtn {
  color: #7B7B7B;
  padding: 8px 15px;
  border: 0;
  border-radius: 0;
  background-color: #fff;
  transition: .2s all ease-in-out;
}

.filterResetBtn:hover {
  color: #000;
}

.filterResetBtn:active,
.filterResetBtn:focus {
  outline: none;
  box-shadow: none;
}

.filterBox{
  border-radius: 0px;
  font-family: 'Raleway', sans-serif;
}

.filterBoxFooter{
  border-top: 0;
  background-color: #fff;
  text-align: right;
}

.filterBoxBtn{
  color: #fff;
  padding: 8px 15px;
  border: 0;
  border-radius: 0;
  margin-left: 10px;
  background-color: #AF621C;
  transition: .2s all ease-in-out;
}

.filterBoxBtn:hover{
  background-color: #6F3E2C !important;
}

.filterBoxBtn:focus,
.filterBoxBtn:active{
  outline: none;
  box-shadow: none;
}

select.filterSelect.form-control{
  border-radius: 0;
}

select.filterSelect.form-control:focus,
select.filterSelect.form-control:active {
  box-shadow: none;
}

/* audio card css end */

/* audio item css start */

.audioItem{
  border: none;
  max-width: 355px;
  max-height: 200px;
  background-color: #003B40;
  margin-bottom: 35px;
  transition: .2s all ease-in-out;
}

.audioItemImg{
  width: 100%;
  height: 100%;
}

.audioItem:hover{
  z-index: 100;
  transform: scale(1.1);
  -webkit-transform:scale(1.1);
  -moz-transform:scale(1.1);
}

.audioViewSum{
  padding-top: 20px;
  font-family: 'Raleway', serif;
  color: #4B4B4B;
}

/* audio item css end */

/* blog seciton css start */
.blogsection{
  padding-top: 100px;
  margin-top: -75px;
  background-image: url('./assets/images/bg-pattern.png');
  background-repeat: repeat;
}

.blogCard{
  margin-top: 50px;
  border-radius: 0px;
}

.blogCardImg{
  width: 100%;
  height: 245px;
  margin-top: -35px;
  border-radius: 0px;
  -webkit-box-shadow: 0 .5rem .75rem rgba(0,0,0,.40);;
	-moz-box-shadow: 0 .5rem .75rem rgba(0,0,0,.40);;
  box-shadow: 0 .5rem .75rem rgba(0,0,0,.40);
}

.blogIconContainer{
  font-family: Arial, Helvetica, sans-serif;
  color: #7B7B7B;
  font-size: 24px;
  padding: 20px 0px 20px 0px;
}

.blogPostTime{
  display: inline;
  max-width: 200px;
}

.blogShare1{
  float: right;
}

.blogShare1 a {
  text-decoration: none;
  color: #7B7B7B;
  transition: .2s all ease-in-out;
}

.blogShare1 a:hover {
  text-decoration: none;
  color: #333333;
}

button.react-share__ShareButton{
  border: none;
  color: #7B7B7B;
  background: none;
  transition: .2s all ease-in-out;
  padding: 0;
}

button.react-share__ShareButton:hover {
  color: #333333;
}

button.react-share__ShareButton:focus {
  outline: none;
}

.blogShare2{
  display: none;
  font-family: Arial, Helvetica, sans-serif;
  color: #7B7B7B;
  font-size: 24px;
  padding: 0px 0px 20px 0px;
}

.blogCardTitle{
  font-size: 22px;
  padding-top: 20px;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.blogCardText{
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  text-align: justify;
  height: 105px;
  overflow: hidden;
  color: #4B4B4B;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.blogCardLinkContainer{
  text-align: right;
  margin: 16px 0px 25px 0px; 
}

.blogCardLink{
  font-family: 'Raleway', serif;
  border: 0;
  padding: 10px 15px;
  text-decoration: none;
  color: #fff;
  background-color: #AF621C;
  border-radius: 0;
  transition: .2s all ease-in-out;
}

.blogCardLink:hover,
.blogCardLink:active,
.blogCardLink:focus {
  color: #fff;
  text-decoration: none;
  background-color: #6F3E2C !important;
  box-shadow: none !important;
}

/* blog section css end */


/* login page css start */

.loginPage{
  height: 100vh;
  background-image: linear-gradient(to bottom right, #00c6ff, #0072ff);
}

.loginContainer{
  text-align: center;
  height: 100%;
}

.loginCard{
  display: block;
  max-width: 450px;
  border-radius: 10px;
  padding: 77px 55px 33px 55px;
  margin-left: auto;
  margin-right: auto;
  background: rgba(0, 0, 0, 0.70);
}

.loginHeader{
  margin-top: 20px;
  font-family: 'Raleway', serif;
  color: #fff;
}

.loginInput{
  border-radius: 0;
  margin-top: 25px;
  background-color: transparent;
  border-left: 0;
  border-right: 0;
  font-family: 'Raleway', serif;
  padding: 25px 5px;
  border-top: 0;
  color: #fff;
  border-bottom: 2px solid #fff;
}

.loginInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

.loginInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
}

.loginInput::-ms-input-placeholder { /* Microsoft Edge */
  color: #fff;
}

.loginInput:focus{
  box-shadow: none;
  color: #fff;
  background-color: transparent;
}

.loginBtn{
  font-family: 'Raleway', serif;
  height: 50px;
  margin-top: 50px;
  border-radius: 25px;
  border: none;
  width: 100%;
  color: #fff;
  background-size: 200% auto;
  transition: 0.4s;
  box-shadow: none;
  background-image: linear-gradient(to right, #b721ff 0%, #21d4fd 51%, #b721ff 100%, #21d4fd 100%)
}

.loginBtn:hover{
  background-position: right center;
}

.loginBtn:focus,
.loginBtn:active{
  outline: none;
}

.loginLink{
  color: #fff;
  font-family: 'Raleway', serif;
  font-size: 14px;
}

.loginLink:hover,
.loginLink:active,
.loginLink:visited {
  color: #fff;
}

/* login page css end */


.notify{
  font-family: 'Raleway', serif;
}

.overviewCard{
  text-align: center;
  padding: 20px;
  margin: 10px 0px;
  background-color: #F8F9FA;
}

.sectionBtn{  
  text-decoration: none;
  background-image: linear-gradient(to bottom right, #00BFFF, #0077FF);
  border: none;
  text-decoration: none;
  border-radius: 5px;
  padding: 10px 20px;
  color: #fff;
}

.sectionBtn:hover,
.sectionBtn:focus,
.sectionBtn:active,
.sectionBtn:visited{  
  color: #fff;
  text-decoration: none;
}

.registerInput{
  border-radius: 0;
}

.registerInput:focus,
.registerInput:active{
  box-shadow: none;
}

.createBtn{
  font-family: 'Raleway', serif;
  border: none;
  border-radius: 5px;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 15px;
  background-image: linear-gradient(to bottom right, #00BFFF, #0077FF);
}

.editLink{
  font-size: 24px;
  color: #fff;
  border: none;
  text-decoration: none;
  padding: 5px 5px 5px 9px;
  border-radius: 5px;
  background-color: #17A2B8;
}

.editLink:hover,
.editLink:active,
.editLink:focus{
  color: #fff;
  background-color: #003B40;
}

.trashBtn{
  font-size: 24px;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  padding: 1px 10px 2px 10px;
  border: none;
  background-color: #DC3545;
}

.trashBtn:hover, 
.trashBtn:focus {
  background-color: #870000;
}

.iframe-container {
  overflow: hidden;
  /* Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625) */
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@media (max-width: 999px) {
  .hideElem {
    display: none;
  }
  .headline-news {
    display: none;
  }
  .masjid-logo{
    padding-left: auto;
    padding-right: auto;
  }
}

@media (max-width: 991px) {
  .masjid-logo{
    margin-left:auto;
    margin-right:auto;
  }

  .blogIconContainer{
    padding: 20px 0px 0px 0px;
  }
}

@media (max-width: 388px) {

  .al-bismillah{
    font-size: 36px;
  }

  .blogShare1{
    display: none;
  }

  .blogShare2{
    display: block;
  }

  .blogCardTitle{
    padding-top: 10px;
  }

  .blogCardBtn{
    margin-bottom: 5px;
  }

  .blogIconContainer{
    padding-top: 10px;
  }
}